import React, { useEffect } from 'react'
import './App.css'
import { Route, Routes, useNavigate } from 'react-router-dom'
import Homepage from './pages/homepage'
import 'aos/dist/aos.css'
import AOS from 'aos'
import FAQPage from './pages/faq'
import { Box, styled } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { injected, networkConf } from './web3/connectors'
import MintPage from './pages/mint'
import useFreeMintContractQuery from './hooks/contract/useFreeMintContractQuery'

const Wrapper = styled('div')`
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  background-color: rgb(245,245,247);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  
`

const NavBarContainer = styled('div')`
  position: fixed;
  top: 0;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box; 
  z-index: 9;
  
  .faq-label {
    font-family: WorkSans-Bold;
    cursor: pointer;
    position: relative;
    font-size: 16px;

    /*effect-underline*/
    :hover{
      color: rgb(225, 101, 65);
    }

    :after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      display: inline-block;
      width: 100%;
      border-bottom: 2px rgba(225, 101, 65, 0.75) solid;
      opacity: 0;
      -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
      transition: opacity 0.35s, transform 0.35s;
      -webkit-transform: scale(0, 1);
      transform: scale(0, 1);
      
    }

    :hover:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
     
    }
  }
`

const CornerRibbon = styled('div')`
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;

  ::before,
  ::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #F18F43;
  }
  span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: #F18F43;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font: 700 18px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
  }

  /* top left*/
  .ribbon-top-left {
    top: -10px;
    left: -10px;
  }
  .ribbon-top-left::before,
  .ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-top-left::before {
    top: 0;
    right: 0;
  }
  .ribbon-top-left::after {
    bottom: 0;
    left: 0;
  }
  .ribbon-top-left span {
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
  }
`

function App() {
  const { activate, account } = useWeb3React()
  const navigate = useNavigate()

  AOS.init({
    duration: 1500,
    easing: 'ease-in-sine',
    delay: 0,
  })

  useEffect(() => {
    const connectWalletOnPageLoad = async () => {
      if (localStorage?.getItem('isWalletConnected') === 'true') {
        try {
          await activate(injected)
          localStorage.setItem('isWalletConnected', 'true')
        } catch (ex) {
          console.log(ex)
        }
      }
    }
    connectWalletOnPageLoad()
  }, [])

  useEffect(():any => {
    const { ethereum } = window as any

    if (ethereum  && account) {
      ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: networkConf[1].chainId }]
      }).catch((e: any) => {
        if (e.code === 4902) {
          ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                ...networkConf[1]
              }
            ],
          })
        }
      })
    }

  },[ account])

  const PeriodStateMap: any = {
    '0': 'nothing',
    '1': 'Free Mint',
    '2': 'Public Sale',
    '3': 'Public Mint',
    '4': 'Public Mint',
    '5': 'Public Mint',
    '6': 'Public Mint',
    '7': 'Public Mint',
    '8': 'Public Mint',
    '9': 'Public Mint',
    '10': 'Public Mint'
  }

  const { periodState } = useFreeMintContractQuery()

  return (
    <div className="App">
      <Wrapper>
        <CornerRibbon>
          <div className="ribbon-top-left">
            { periodState.data && <span>{PeriodStateMap[periodState.data.toString()]}</span> }
          </div>
        </CornerRibbon>
        {/*<NavBarContainer>*/}
        {/*  <Box sx={{ display: 'flex', justifyContent: 'flex-end', width:'100%' }}>*/}
        {/*    <div className={'faq-label'} onClick={() => navigate('faq')}>FAQ</div>*/}
        {/*  </Box>*/}
        {/*</NavBarContainer>*/}
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/mint" element={<MintPage />} />
          <Route path="/faq" element={<FAQPage />} />
        </Routes>
      </Wrapper>
    </div>
  )
}

export default App
