import { useWeb3React } from '@web3-react/core'
import useSigner from '../useSigner'
import { useCallback, useEffect, useMemo } from 'react'
import { Contract, ethers, providers } from 'ethers'
import { useQuery } from 'react-query'
import { FREE_MINT_CONTRACT_ADDRESS, MAINNET_API_KEY, MAINNET_NETWORK } from './constant'
import { FreeMintAbi } from './abi/freeMintAbi'
import { useRefreshController } from '../../contexts/refresh-controller'

const useFreeMintContractQuery = () => {
  const { account, library } = useWeb3React()
  const { quietRefreshFlag } = useRefreshController()

  const contract = useMemo(() => {

    const provider = new providers.AlchemyProvider(MAINNET_NETWORK, MAINNET_API_KEY)

    return new Contract(FREE_MINT_CONTRACT_ADDRESS, FreeMintAbi, provider)
  }, [account, library])

  const periodState = useQuery(['SALE_STATE', contract?.address, quietRefreshFlag], async () => {
    if (!contract) return
    const periodState = await contract.periodState()

    return periodState
  }, {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true
  })

  const periodAndPrice = useQuery(['SALE_PERIOD', contract?.address, quietRefreshFlag], async () => {
    if (!contract) return

    const periodState = await contract.periodState()

    if (periodState.toString() === '0') return undefined

    const period = await contract.periodInfo(periodState)

    const { price, sign, limitPerWallet,curSupply , supply } = period

    return {
      periods: [...period],
      price,
      sign,
      limitPerWallet,
      curSupply,
      supply
    }
  },{
    refetchInterval: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true
  })

  const totalSupply = useQuery(['TOTAL_SUPPLY', contract?.address, quietRefreshFlag], async () => {
    if (!contract) return

    const totalSupply = await contract.totalSupply()
    return totalSupply.toString()
  },{
    refetchInterval: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true
  })

  return {
    periodState, periodAndPrice, totalSupply
  }
}

export default useFreeMintContractQuery
