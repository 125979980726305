import React, { useCallback, useEffect, useState } from 'react'
import { Button, styled, Tooltip } from '@mui/material'
import LogoImage from '../../images/logo.jpg'
import useFreeMintContractQuery from '../../hooks/contract/useFreeMintContractQuery'
import { BigNumber, ethers } from 'ethers'
import { getCurrentBlockTime, shortenAddress } from '../../utils'
import useMint, { VariantType } from '../../hooks/contract/useMint'
import { injected } from '../../web3/connectors'
import { useWeb3React } from '@web3-react/core'
import { NumericStepper } from '@anatoliygatt/numeric-stepper'
import '../homepage/components/numeric.scss'
import { WHITELIST } from '../../contexts/whitelist'
import { BeatLoader } from 'react-spinners'
import { LeftArrow } from '../../images/svgs/icons'
import { useNavigate } from 'react-router-dom'
import { useUserWhiteListBalanceQuery } from '../../hooks/contract/useUserWhiteListBalanceQuery'
import Countdown from 'react-countdown'
import { CountdownRerender } from './countdown'
import OpenseaLogo from '../../images/svgs/opensea.svg'

const NOTHING = '0'
const FREE_STATE = '1'
const PUBLIC_STATE = ['2','3','4','5','6','7','8','9','10']
const WHITELIST_MINT_START_TIME = 1658214000

const getMessageVariant = ({ variant }:any) => {
  switch (variant) {
  case 'success': return { color: '#8B9862' }
  case 'warning': return { color: '#E16541' }
  }
}

const Wrapper = styled('div')`
  width: 100vw;
  min-height: 100vh;
  height: fit-content;
 position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
`

const Title = styled('div')`
  font-size: 20px;
  color: #CA4246;
`

const Container = styled('div')`
  max-width: 1100px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  height: fit-content;
  
  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
    margin-top: 180px;
  }
  
`

const MintInfos = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  background-color: rgb(237,221,212);
  border-radius: 1.5rem;
  max-width: 420px;
  width: 100%;
  font-size: 1.15rem;
  position: relative;


`

const Messages = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: Montserrat-Bold;
  color: #282c34;
  line-height: 40px;
  text-align: left;
  
  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
    font-size: 14px;
  }
  
`

const Logo = styled('div')`
  position: relative;
  width: 140px;
  border-radius: 100%;
  margin-bottom: 2rem;
  margin-top: calc(-1.5rem - 80px);
  box-shadow: #fff 0px 0px 12px;
  
  img {
    width: 100%;
    object-fit: cover;
    border-radius: 100%;
  }
`
const TextItem = styled('div')`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Label = styled('div')`
  margin-bottom: 0.5rem;
  color: black;
  font-family: Montserrat-Regular;
`

const WalletLabel = styled('div')`
  font-family: Montserrat-Regular;
  cursor: pointer;
  text-decoration: underline #282c34;
`

const Value = styled('div')`
  font-family: Montserrat-Bold;
  color: #CA4246;
`

const StyledTooltip = styled(Tooltip)`
  
`

const StyledButton = styled(Button)`
  text-transform: none;
  font-family: Montserrat-Bold;
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 20px;

  &.MuiButton-root.Mui-disabled {
    color: white;
    background-color: rgba(225, 101, 65, 0.56);
  }
`

const Message = styled('div')<{variant?: VariantType}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;


  ${getMessageVariant}

`

const BackHome = styled('div')`
 top: 13%;
  left: 10%;
  position: fixed;
  
  .back {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 7px;
    background: rgb(237,221,212);
    cursor: pointer;
  }
  
`

const LogoContainer = styled('div')`
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #282c34;
  padding: 8px 16px;
  border-radius: .4rem;
  margin-bottom: 20px;
  img {
    width: 100%;
    height: 100%;
      }
`

const MintPage:React.FC = () => {
  const { account ,deactivate, activate } = useWeb3React()
  const {  freeMint, loading, message } = useMint()
  const { data: userRemainMints } = useUserWhiteListBalanceQuery()
  const { periodState, periodAndPrice, totalSupply } = useFreeMintContractQuery()
  const [mintAmount, setMintAmount] = useState<number | undefined>( userRemainMints?.toNumber())
  const [inWhitelist, setInWhitelist] = useState<boolean >(false)
  const [localUserRemainMints, setLocalUserRemainMints] = useState<BigNumber | undefined>()
  const navigate = useNavigate()
  const [countdown, setCountdown] = useState<number | undefined>(WHITELIST_MINT_START_TIME * 1000)
  const  currentBlockTime  = getCurrentBlockTime()
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)

  useEffect(() => {
    if (!currentBlockTime) return

    if (currentBlockTime < WHITELIST_MINT_START_TIME) {
      setButtonDisabled(true)
      setCountdown(WHITELIST_MINT_START_TIME * 1000)
    } else {
      setButtonDisabled(false)
      setCountdown(undefined)
    }

  }, [countdown, currentBlockTime, buttonDisabled])

  useEffect(() => {
    if (!localUserRemainMints && userRemainMints) {
      setLocalUserRemainMints(userRemainMints)
    }
  }, [userRemainMints, localUserRemainMints])

  const connect = useCallback( () => {
    try {
      activate(injected).then(() => {
        localStorage.setItem('isWalletConnected', 'true')
      })
    }
    catch (ex) {
      console.log(ex)
    }
  }, [ activate])

  async function disconnectEthWallet() {
    try {
      deactivate()
      localStorage.setItem('isWalletConnected', 'false')
    } catch (ex) {
      console.log(ex)
    }
  }

  useEffect(() => {
    // in whitelist period
    if ( periodState?.data?.toString() !== FREE_STATE && account) {
      const exist = account.toLowerCase() in WHITELIST
      setInWhitelist(exist)
    }
  }, [periodState, account,userRemainMints, inWhitelist])

  const handleFreeMint = useCallback(() => {
    freeMint(mintAmount)
      .then(() => {
        setLocalUserRemainMints(prev => {
          return prev?.gte(1) ? prev?.sub(1) : prev
        })
      })
      .catch((er:any) => {
        console.log('er',er)
      })
  }, [freeMint, mintAmount])

  return (
    <Wrapper>
      <BackHome>
        <div className={'back'} onClick={() => navigate('/')}>
          <LeftArrow  />
        </div>
      </BackHome>
      <Container>
        <MintInfos>
          <Logo>
            <img src={LogoImage} />
          </Logo>

          {
            periodState?.data?.toString() !== NOTHING && (
              <TextItem>
                <Label >Mint Price:</Label>
                {
                  periodAndPrice?.data?.price ?
                    <>
                      {
                        periodAndPrice.data.price.toString() !== '0' ?
                          <Value >
                            {ethers.utils.formatEther(periodAndPrice.data.price)} ETH
                          </Value> :
                          <Value> FREE (gas only)</Value>
                      }
                    </>
                    :
                    <Value >
                      <BeatLoader size={6} color={'#CA4246'} />
                    </Value>
                }
              </TextItem>
            )
          }

          <TextItem>
            <Label > Total Supply:</Label>
            <Value>{totalSupply.data ?   totalSupply.data.toString() + '/' + 10000 : <BeatLoader size={6} color={'#CA4246'} /> }</Value>
          </TextItem>

          {
            periodState?.data?.toString() !== NOTHING && (
              <TextItem>
                <Label >Remaining:</Label>
                <Value>{
                  (periodAndPrice.data?.curSupply && periodAndPrice.data.supply) ?
                    periodAndPrice.data.supply.sub(periodAndPrice.data.curSupply).toString()
                    :
                    <BeatLoader size={6} color={'#CA4246'} />
                }
                </Value>
              </TextItem>
            )
          }

          <TextItem>
            {
              (periodState?.data && periodState?.data?.toString() !== NOTHING && account) &&
                <>
                  <Label>Wallet remain mint amount: </Label>
                  {/*{*/}
                  {/*  (PUBLIC_STATE.includes(periodState.data.toString()) &&  userRemainMints) && <Value>{userRemainMints?.toString()}</Value>*/}
                  {/*}*/}

                  {/*whitelist period*/}
                  {
                    periodState?.data?.toString() === FREE_STATE &&  (
                      <Value>
                        {
                          localUserRemainMints ? localUserRemainMints?.toString() : <BeatLoader size={6} color={'#CA4246'} />
                        }
                      </Value>
                    )
                  }

                  {
                    (PUBLIC_STATE.includes(periodState.data.toString()) &&  userRemainMints) && <Value>NO LIMIT </Value>
                  }
                </>
            }

          </TextItem>

          <TextItem>
            {
              periodState?.data?.toString() === NOTHING  && <Label >Mint hasn&apos;t started</Label>
            }

          </TextItem>

          <TextItem>
            {
              account ?
                <>
                  <NumericStepper
                    minimumValue={1}
                    maximumValue={userRemainMints?.toNumber()}
                    initialValue={1}
                    inactiveTrackColor="#CA4246"
                    activeTrackColor="#fddec0"
                    activeButtonColor="#ffedd5"
                    inactiveIconColor="#fb923c"
                    hoverIconColor="#ea580c"
                    activeIconColor="#9a3412"
                    disabledIconColor="#fdba74"
                    thumbColor="#a12327"
                    thumbShadowAnimationOnTrackHoverEnabled={false}
                    focusRingColor="#fff7ed"
                    onChange={value => {setMintAmount(value)}}
                  />

                  {
                    periodState?.data?.toString() === FREE_STATE && (
                      <StyledButton
                        disabled={userRemainMints?.toString() === '0' || localUserRemainMints?.toString() === '0'  || loading  }
                        onClick={handleFreeMint}
                        variant={'contained'}
                      >
                        FREE MINT
                      </StyledButton>

                    )
                  }

                  {
                    periodState?.data && PUBLIC_STATE.includes(periodState.data.toString()) && (
                      <StyledButton disabled={loading} onClick={handleFreeMint} variant={'contained'}>PUBLIC MINT</StyledButton>
                    )
                  }

                  <StyledTooltip title="Click to disconnect">
                    <WalletLabel onClick={disconnectEthWallet}>{shortenAddress(account)}</WalletLabel>
                  </StyledTooltip>
                </>
                :
                <Button onClick={connect} variant={'contained'} disabled={periodState?.data?.toString() === NOTHING}>
                  Connect Wallet
                </Button>

            }
          </TextItem>

          <Message variant={message?.variant}>
            {

              message?.variant === 'warning' ?
                <>  Warning: {message?.msg?.toString()}</>
                :
                <>  {message?.msg?.toString()}</>

            }
          </Message>
        </MintInfos>
        <Messages >
          {
            currentBlockTime && countdown &&
              <Countdown
                now={() =>currentBlockTime * 1000 }
                renderer={CountdownRerender}
                onComplete={() => setButtonDisabled(false)}
                date={countdown}
              />
          }
          <Title>A little bit of something is better than all of nothing.</Title><br />
          <a href={'https://opensea.io/collection/its-sth'} target="_blank" rel="noreferrer">
            <LogoContainer>
              <img src={OpenseaLogo} alt={'opensea'} />
            </LogoContainer>
          </a>
          <li>Something mysterious is coming.</li>
          <li>1,000 Somebody will be selected by luck.</li>
          <li>Someones will be called as Something ambassadors</li>
          <li>Somewhere will be built after Something is revealed</li>
          <li> great event with a sense of mission will be opened</li>

        </Messages>
      </Container>
    </Wrapper>
  )
}

export default MintPage
