import { Button, styled } from '@mui/material'

export const BlobButton = styled(Button)`
  position: relative;
  z-index: 0;
  display: inline-block;
  font-size: 20px;
  font-family: WorkSans-Medium;
  text-transform: none;
  box-sizing: border-box;

  &:hover {
    background-color: transparent;
  }
  

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 160px;
    height: 40px;
    font-size: 16px;
  }

  .button__self {
    -webkit-appearance: none;
    position: relative;
    overflow: visible;
    display: block;
    width: calc(100% + 25px);
    height: calc(100% + 5px);
    border: 0;
    text-align: center;
    font-size: inherit;
    line-height: inherit;
    border-radius: 9in;
    background: linear-gradient(90deg, #f5ae4a, #f76e54);
    color: #fff;
    cursor: pointer;
    outline: 0;
    transition: .3s;
  }


  .button__self::before {
    content: '';
    opacity: 0.75;
    transform: translateY(20%) scale(0.9);
    filter: blur(4px);
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: inherit;
    transition: inherit;
  }

  .button__inner {
    position: relative;
    overflow: hidden;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: inherit;
    transition: 125ms;
  }

  .button__icon,
  .button__text {
    position: relative;
    margin: 0 0.25em;
  }

  .button__icon {
    font-size: 24px;
    width: 1em;
    height: 1em;
  }

  .button__inner::before {
    content: "";
    transform: skew(-20deg) translateX(-100%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
        90deg,
        hsla(0, 0%, 100%, 0),
        hsla(0, 0%, 100%, 0.3),
        hsla(0, 0%, 100%, 0)
    );
  }

  .button__self:hover {
    transform: translateY(-2px);

  }

  .button__self:hover::before {
    opacity: 0.4;
    transform: translateY(35%) scale(0.9);
    
  }

  .button__self:hover .button__inner {
    background: hsla(0, 0%, 100%, 0.1);
   
  }

  .button__self:hover .button__inner::before {
    animation: shine 0.75s both;
    
  }

  .button__self:active .button__inner {
    background: hsla(0, 0%, 0%, 0.05);
  }

  @keyframes shine {
    to {
      transform: translateX(100%);
    }
  }

`

export const TextEffect = styled('div')<{message?: string}>`
  color: #fff;
  font-family: WorkSans-Medium;
width: 100%;
  height: 100%;
  transition: all 0.2s linear 0s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: inherit;
  text-transform: none;

  &:before { 
    content: "${({ message }) => message}";
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 100%;
    left: 0px;
    width: 100%;
    height: 100%;
    transition: all 0.2s linear 0s;
  }

  //&:hover {
  //  text-indent: -9999px;
  //
  //  &:before {
  //    top: 0;
  //    text-indent: 0;
  //  }
  //}

  :hover {
    color: transparent;

      &:before {
        top: 0;
        text-indent: 0;
        color: white;
      }
  }
`
