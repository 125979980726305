import { useEffect, useState } from 'react'
import { providers } from 'ethers'
import { MAINNET_API_KEY, MAINNET_NETWORK } from '../hooks/contract/constant'

export const shortenAddress = (address?: string, length = 6) => {
  return address ? `${address.substring(0, length)}...${address.slice(-length)}` : '-'
}

export const getCurrentBlockTime = () => {
  const [time, setTime] = useState<number>()
  const provider = new providers.AlchemyProvider(MAINNET_NETWORK, MAINNET_API_KEY)
  useEffect( () => {
    const currentTime =  () => {
      provider.getBlockNumber().then(blockNumber => {
        provider.getBlock(blockNumber).then(time => {
          if (time.timestamp) {
            setTime(time.timestamp)
          }
          else currentTime()
        })
      })
    }

    currentTime()
  }, [])

  useEffect(() => {
    const id = setInterval(() => {
      setTime(prev => (prev ? prev + 1 : undefined))
    },1000)

    return () => {
      clearInterval(id)
    }

  }, [])

  return time

}
