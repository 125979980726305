import { InjectedConnector } from '@web3-react/injected-connector'
import { AbstractConnector } from '@web3-react/abstract-connector'

import { Web3Provider } from '@ethersproject/providers'

export const injected = new InjectedConnector({})

export enum WalletKeys {
  MetaMask = 'MetaMask',
}

export type WalletType = {
  key?: WalletKeys,
  name: string
  icon: string,
  connector?: InjectedConnector,
}

export const supportWallets: WalletType[] = [
  {
    name: 'MetaMask',
    key: WalletKeys.MetaMask,
    icon: '',
    connector: injected,
  },

]

export const SCAN_ADDRESS = {
  4: 'https://rinkeby.infura.io/v3/908e9402079b440da6f8b33b638b9355',
  1: 'https://eth-mainnet.g.alchemy.com/v2/5CPKXUc2powZTNad0mAV2aWeQaUgqV9i'
}

export const networkConf = {
  4: {
    chainId: '0x' + parseInt('4').toString(16),
    chainName: 'Ethereum Rinkeby Testnet',
    nativeCurrency: {
      name:'Rinkeby Testnet',
      symbol:'ETH',
      decimals:18,
    },
    rpcUrls: [
      'https://rinkeby.infura.io/v3/908e9402079b440da6f8b33b638b9355'
    ],
    blockExplorerUrls: [SCAN_ADDRESS[4]]
  },
  1: {
    chainId: '0x' + parseInt('1').toString(16),
    chainName: 'Ethereum Mainnet',
    nativeCurrency: {
      name:'Ethereum Mainnet',
      symbol:'ETH',
      decimals:18,
    },
    rpcUrls: [
      'https://eth-mainnet.g.alchemy.com/v2/5CPKXUc2powZTNad0mAV2aWeQaUgqV9i'
    ],
    blockExplorerUrls: [SCAN_ADDRESS[1]]
  },

}

export function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}
