import { CountdownRendererFn } from 'react-countdown'
import { styled } from '@mui/material/styles'
import React from 'react'
import { Box } from '@mui/material'

const StyledCountdown = styled('div')`
  font-size: 22px;
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  font-family: Montserrat-Bold;
  color: #CA4246;
  margin-bottom: 20px;


`

const Col = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 22px;
  
  .value {
    font-size: 20px;
  }
  
  .label {
    font-size: 16px;
  }
`

const Text = styled('div')`
  font-size: 22px;
  margin-bottom: 20px;
  color: #CA4246;
`

export const CountdownRerender: CountdownRendererFn = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return
  } else {
    // Render a countdown
    return (
      <StyledCountdown>
        <Text>Whitelist mint will be start in:</Text>
        <Box sx={{ display:'flex', gap:'20px' }}>
          <Col>
            <div className={'value'}>{hours}</div>
            <div className={'label'}>Hours</div>
          </Col>
          <Col>
            <div className={'value'}>{minutes}</div>
            <div className={'label'}>Minutes</div>
          </Col>
          <Col>
            <div className={'value'}>{seconds}</div>
            <div className={'label'}>Seconds</div>
          </Col>
        </Box>

      </StyledCountdown>
    )
  }
}

