import React, {  useState } from 'react'
import { Box, styled } from '@mui/material'
// @ts-ignore
import MovingText from 'react-moving-text'
import useFreeMintContractQuery from '../../hooks/contract/useFreeMintContractQuery'
import { BlobButton, TextEffect } from '../../theme/components/BlobButton/index.styles'
import { shortenAddress } from '../../utils'
import { useNavigate } from 'react-router-dom'
import { CubeEffect, } from '../../theme/components/CubeEffectButton'
import { CustoButton } from '../../theme/components/CustoButton'

const Wrapper = styled('div')`
  width: 100vw;
  height: 100vh;
 position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  
`

const Text = styled('div')`
  color: rgb(0, 0, 0);
  font-family: WorkSans-ExtraBoldItalic, sans-serif;
  font-weight: 800;
  font-size: 5em;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 3.5em;
  }

  .rainbow-text {
    user-select: none;
    /* Create a conic gradient. */
    /* Double percentages to avoid blur (#000 10%, #fff 10%, #fff 20%, ...). */
    background: #a12327;
    background-color: #CA4246;
    background: conic-gradient(#CA4246 16.666%,
    #E16541 16.666%,
    #E16541 33.333%,
    #F18F43 33.333%,
    #F18F43 50%,
    #8B9862 50%,
    #8B9862 66.666%,
    #476098 66.666%,
    #476098 83.333%,
    #A7489B 83.333%);

    background-size: 57%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: rainbow-text-animation-rev 0.5s ease forwards;
    cursor: pointer;

  }

  .rainbow-text:hover {
    animation: rainbow-text-animation 0.5s ease forwards;

  }

  @keyframes rainbow-text-animation {
    0% {
      background-size: 57%;
      background-position: 0 0;
    }
    20% {
      background-size: 57%;
      background-position: 0 1em;
    }
    100% {
      background-size: 200%;
      background-position: -9em 1em;

    }
  }

  /*effect-underline*/

  :after {
    content: '';
    position: absolute;
    left: 0;
    display: inline-block;
    width: 100%;
    border-bottom: 2px rgba(225, 101, 65, 0.75) solid;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
  }

  :hover:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

`

const Homepage:React.FC = () => {
  const AnimationsForChaining = ['fadeOutToLeft', 'fadeInFromRight']
  const [ animationIndex, setAnimationIndex ] = useState(0)
  const [ animationType, setAnimationType ] = useState(AnimationsForChaining[0])
  const [delay, setDelay] = useState<string>('1600ms')
  const navigate = useNavigate()
  const [letters, setLetters] = useState<string>('it\'S')

  const handleChainAnimation = () => {
    setAnimationIndex(animationIndex + 1)
    setAnimationType(AnimationsForChaining[animationIndex +1 ])
    setDelay('0ms')
    setLetters('SOMETHING')
  }

  return (

    <Wrapper>

      <Box sx={{ display:'flex', height:'20%', flexDirection:'column', alignItems:'center', justifyContent:'space-between' }}>
        <MovingText
          onAnimationEnd={handleChainAnimation}
          type={animationType}
          duration="1000ms"
          delay={delay}
          timing="linear"
          iteration={1}
        >

          {
            letters === 'it\'S' ?
              <Text>
                {letters}
              </Text>
              :
              <Text>
                <div className={'rainbow-text'}>
                  {letters}
                </div>
              </Text>
          }
        </MovingText>

        <BlobButton onClick={() => navigate('mint')} >
          <div className="button__self">
            <div className="button__inner">
              START MINTING
            </div>
          </div>
        </BlobButton>

        {/*<CustoButton onClick={() => navigate('mint')} >*/}
        {/*  <div className="btn btn-default btn-xl">Start minting</div>*/}
        {/*</CustoButton>*/}

      </Box>
      {/*data-aos="fade-up" data-aos-dutation={8000}*/}

    </Wrapper>

  )
}

export default Homepage
