import { useQuery, UseQueryResult } from 'react-query'
import { useRefreshController } from '../../contexts/refresh-controller'
import { useWeb3React } from '@web3-react/core'
import useSigner from '../useSigner'
import { useMemo } from 'react'
import { FREE_MINT_CONTRACT_ADDRESS, MAINNET_API_KEY, MAINNET_NETWORK } from './constant'
import { FreeMintAbi } from './abi/freeMintAbi'
import { BigNumber, Contract, providers } from 'ethers'

export const useUserWhiteListBalanceQuery = () => {
  const { account, library } = useWeb3React()
  const { quietRefreshFlag } = useRefreshController()

  const contract = useMemo(() => {
    const provider = new providers.AlchemyProvider(MAINNET_NETWORK, MAINNET_API_KEY)

    return new Contract(FREE_MINT_CONTRACT_ADDRESS, FreeMintAbi, provider )
  }, [account, library])

  return useQuery<BigNumber | undefined>(
    ['MINT_REMAIN', account, quietRefreshFlag, contract?.address, ],
    async () => {
      if (!contract || !account ) return
      const remainMints = await contract.remainMints(account)

      if (!remainMints) return 0

      return remainMints
    },
    {
      keepPreviousData: false,
      refetchInterval: false
    }
  )
}

