import { useWeb3React } from '@web3-react/core'
import useSigner from '../useSigner'
import { useCallback, useMemo, useState } from 'react'
import { BigNumber, Contract } from 'ethers'
import { FREE_MINT_CONTRACT_ADDRESS } from './constant'
import useFreeMintContractQuery from './useFreeMintContractQuery'
import { useUserWhiteListBalanceQuery } from './useUserWhiteListBalanceQuery'
import { WHITELIST } from '../../contexts/whitelist'
import { useRefreshController } from '../../contexts/refresh-controller'

export type VariantType = 'success' | 'warning'

type MessageType = {
    msg: string,
    variant?: VariantType
}

const useMint = () => {
  const signer = useSigner()
  const { account, library } = useWeb3React()
  const {  periodAndPrice, periodState } = useFreeMintContractQuery()
  const { data: userRemainMints } = useUserWhiteListBalanceQuery()
  const { forceRefresh } = useRefreshController()
  const [loading, setLoading] = useState<boolean>(false)
  const [message, setMessage] = useState<MessageType | undefined>()

  const contract = useMemo(() => {
    if (!account || !signer) { return undefined }
    return new Contract(FREE_MINT_CONTRACT_ADDRESS, require('./abi/freeMintAbi.json'), signer)
  }, [account, library, signer])

  const freeMint = useCallback(
    (mintAmount: number | undefined) => {
      if (!contract || !periodAndPrice.data?.price || !mintAmount || !account) return Promise.reject()
      setLoading(true)

      const userMintAmount = BigNumber.from(mintAmount)
      let signature: any[] = []

      // needs sign
      if (periodAndPrice.data?.sign) {
        if (account in WHITELIST) {
          signature = WHITELIST[account] as unknown as any[]
        }
        else return Promise.reject()
      }

      return contract.mint(userMintAmount, signature, { value: periodAndPrice.data.price.mul(userMintAmount) })
        .then(() => {
          forceRefresh()
          setLoading(false)
          setMessage({ msg: 'Mint successfully! Please refresh this page', variant: 'success' })

          return true
        }).catch((err: any) => {
          setMessage({ msg: err.message, variant: 'warning' })
          setLoading(false)

          return Promise.reject(new Error(err))
        })
    },
    [contract, account, periodAndPrice, userRemainMints, periodState],
  )

  return {
    freeMint, userRemainMints, loading, message
  }
}

export default useMint
